import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuardService} from "./services/auth-guard/auth-guard.service";
import {BranchResolver} from "./services/branch/branch.resolver";
import {EmployeeResolver} from "./services/employee/employee.resolver";
import {MenuService} from "./services/auth-guard/menu.service";

const routes: Routes = [
  { path: '', loadChildren: './pages/frontend/appointment/appointment.module#AppointmentPageModule', canActivate: [MenuService] },
  { path: 'form/:appointmentId', loadChildren: './pages/frontend/form/form.module#FormPageModule', canActivate: [MenuService] },
  { path: 'summary/:appointmentId', loadChildren: './pages/frontend/summary/summary.module#SummaryPageModule', canActivate: [MenuService] },
  { path: 'imprint', loadChildren: './pages/frontend/imprint/imprint.module#ImprintPageModule' , canActivate: [MenuService]},
  { path: 'privacy', loadChildren: './pages/frontend/privacy/privacy.module#PrivacyPageModule', canActivate: [MenuService] },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' , canActivate: [MenuService] },
  { path: 'dashboard', loadChildren: './pages/dashboard/dashboard.module#DashboardPageModule', canActivate: [AuthGuardService] },
  { path: 'branch/list', loadChildren: './pages/branch/list/list.module#ListPageModule', canActivate: [AuthGuardService] },
  { path: 'branch/new', loadChildren: './pages/branch/new/new.module#NewPageModule', canActivate: [AuthGuardService] },
  { path: 'branch/detail/:id', loadChildren: './pages/branch/detail/detail.module#DetailPageModule', canActivate: [AuthGuardService], resolve: {branch: BranchResolver} },
  { path: 'employee/list', loadChildren: './pages/employee/list/list.module#ListPageModule', canActivate: [AuthGuardService] },
  { path: 'employee/new', loadChildren: './pages/employee/new/new.module#NewPageModule', canActivate: [AuthGuardService] },
  { path: 'employee/detail/:id', loadChildren: './pages/employee/detail/detail.module#DetailPageModule', canActivate: [AuthGuardService], resolve: {employee: EmployeeResolver} },
  { path: 'appointment/booked', loadChildren: './pages/appointment/booked/booked.module#BookedPageModule', canActivate: [AuthGuardService] },
  { path: 'appointment/booked/:id', loadChildren: './pages/appointment/booked-detail/booked-detail.module#BookedDetailPageModule', canActivate: [AuthGuardService] },
  { path: 'appointment/free', loadChildren: './pages/appointment/free/free.module#FreePageModule', canActivate: [AuthGuardService] },
  { path: 'settings/services', loadChildren: './pages/settings/services/services.module#ServicesPageModule', canActivate: [AuthGuardService] },
  { path: 'calendar/view', loadChildren: './pages/calendar/view/view.module#ViewPageModule', canActivate: [AuthGuardService]},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  providers: [
      BranchResolver,
      EmployeeResolver
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
