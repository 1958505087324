import { Injectable } from '@angular/core';
import {ApiService} from "../api/api.service";

import { listitem } from "./listitem";
import { detailitem } from "./detailitem";
import {roomListitem} from "./roomListitem";
import {roomDetailitem} from "./roomDetailitem";
import {serviceItem} from "./serviceItem";

@Injectable({
  providedIn: 'root'
})
export class BranchService {

    private branchObject: detailitem;

    constructor(private api: ApiService) { }

    setBranchObject(branch : detailitem) {
        this.branchObject = branch;
    }

    getBranchObject() : Promise<detailitem> {
        return Promise.resolve(this.branchObject);
    }

    /**
     * Load branch list items
     * @returns {Promise<listitem[]>}
     */
    loadListItems() : Promise<listitem[]> {
        let params = {
            "select": ["id", "title", "isActive", "sorting", "nameInternal", "shortname", "street", "zip", "city", "color"],
            "from": "Branch",
            "where": {"isActive=?": true},
            "orderBy": {"sorting": "ASC"}
        };

        return this.api.post('/api/query', params).then((listitems: listitem[]) => {
            return Promise.resolve(listitems);
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    /**
     * Load branch data
     * @param {string} id
     * @returns {Promise<detailitem>}
     */
    loadBranchData(id : string) : Promise<detailitem> {
        let params = {
            "select": ["*"],
            "from": "Branch",
            "where": {"id=?": id}
        };
        return this.api.post('/api/query', params).then((result: any) => {
            this.setBranchObject(result['data'][0]);
            return Promise.resolve(result['data'][0]);
        }).catch((error) => {
            return Promise.reject(error);
        })
    }

    /**
     * Load all rooms for the specified branch
     * @param {string} branchId
     * @returns {Promise<roomListitem[]>}
     */
    loadBranchRoomItems(branchId : string) : Promise<roomListitem[]> {
        return this.api.get('/api/branch/'+branchId+'/rooms').then((rooms : roomListitem[]) => {
            return Promise.resolve(rooms);
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    /**
     * Load room detail item
     * @param {string} roomId
     * @returns {Promise<roomDetailitem>}
     */
    loadBranchRoomDetail(roomId : string) : Promise<roomDetailitem> {
        return this.api.get('/api/branch/rooms/'+roomId).then((room : roomDetailitem) => {
            return Promise.resolve(room);
        }).catch((error) => {
            console.log(error);
            return Promise.reject(error);
        });
    }

    /**
     * Load all services
     * @param {string} roomId
     * @returns {Promise<serviceItem[]>}
     */
    loadBranchRoomServices(roomId : string = null) : Promise<serviceItem[]> {
        let api = '/api/branch/services';
        if(roomId) {
            api += '/'+roomId;
        }
        return this.api.get(api).then((services : serviceItem[]) => {
            return Promise.resolve(services);
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    /**
     * Delete room
     * @param {roomListitem} room
     * @returns {Promise<any>}
     */
    deleteRoom(room : roomListitem) : Promise<any> {
        let params = {
            "entity": "Branch\\Room",
            "id": room.id
        };
        return this.api.post('/api/delete', params).then((response: any) => {
            return Promise.resolve(response);
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    /**
     * Save branch
     * @param {detailitem} branch
     * @param {string} id
     * @returns {Promise<object>}
     */
    saveBranch(branch : detailitem, id: string = null) : Promise<object> {
        let params = {
              "entity": "Branch\\Branch",
              "data": {
                  "title": branch.title,
                  "shortname": branch.shortname,
                  "nameInternal": branch.nameInternal,
                  "street": branch.street,
                  "zip": branch.zip,
                  "city": branch.city,
                  "state": branch.state,
                  "phone": branch.phone,
                  "fax": branch.fax,
                  "email": branch.email,
                  "website": branch.website,
                  "color": branch.color,
                  "colorTint": branch.colorTint,
                  "colorShade": branch.colorShade
          }
        };
        if(id) {
            params["id"] = id;
            return this.api.post('/api/update', params).then((response: any) => {
                this.setBranchObject(branch);
                return Promise.resolve(response);
            }).catch((error) => {
                return Promise.reject(error);
            })
        } else {
          return this.api.post('/api/insert', params).then((response: any) => {
              this.setBranchObject(branch);
              return Promise.resolve(response);
          }).catch((error) => {
                return Promise.reject(error);
          })
        }

    }

    /**
     * Save room
     * @param {roomDetailitem} room
     * @param {string} id
     * @returns {Promise<object>}
     */
    saveBranchRoom(room : roomDetailitem, id: string = null) : Promise<object> {
        let params = {
            "entity": "Branch\\Room",
            "data": {
                "title": room.title,
                "description": room.description,
                "services": room.services
          }
        };

        if(room.branch) {
            params['data']['branch'] = room.branch;
        }
        if(id) {
            params["id"] = id;
            return this.api.post('/api/update', params).then((response: any) => {
                return Promise.resolve(response);
            }).catch((error) => {
                return Promise.reject(error);
            });
        } else {
            return this.api.post('/api/insert', params).then((response: any) => {
                return Promise.resolve(response);
            }).catch((error) => {
                return Promise.reject(error);
            });
        }
    }
}
