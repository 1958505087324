import { Injectable } from '@angular/core';
import {ApiService} from "../api/api.service";
import {HttpClient} from "@angular/common/http";
import {ADMIN_GROUP, CONTENTFLY_URL} from "../../config";
import { Storage } from '@ionic/storage';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public user: any = null;

    constructor(private api: ApiService, private http : HttpClient, private storage: Storage) {
        this.checkAuthentication();
    }

    /**
     * Login action for authentication in Contentfly-CMS
     * @param {string} username
     * @param {string} password
     * @return {Promise<object>}
     */
    login(username : string, password : string) : Promise<any> {
        let url = CONTENTFLY_URL+'/auth/login';
        let params = {
            "alias": username,
            "pass": password
        };

        return this.http.post(url, params).toPromise().then((response) => {
            if(typeof (response['user']['group']) === 'object') {
                response['user']['isFrontendAdmin'] = (response['user']['group']['id'] === ADMIN_GROUP) ? true : false;
                this.storage.set('token', response['token']);
                this.storage.set('user', response['user']);
                this.user = response['user'];
                return Promise.resolve(response);
            }   
            return Promise.reject(null);;
        }).catch((error) => {
            return Promise.reject(error);
        });
    }


    /**
     * Logout action
     * @return {Promise<object>}
     */
    logout() : Promise<object> {
        return this.api.get('/auth/logout').then((data) => {
            return this.storage.clear();
        }).then((res:any) => {
            return res;
        }).catch((err) => {
            return Promise.reject(err);
        });
    }

    async checkAuthentication(): Promise<any> {
        return this.storage.get('user').then((user) => {
            this.user = user;
            return user;
        }).catch((err) => {
            return null;
        });
    }

    async isAuthenticated(): Promise<boolean> {
        return this.checkAuthentication().then((user:any) => {
           return (user !== null) ? true : false;
        });
    }

    async isFrontendAdmin(): Promise<boolean> {
        return this.checkAuthentication().then((user:any) => {
            return (user !== null) ? user.isFrontendAdmin : false; 
        });
    }

}
