import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from "@angular/common/http";
import {from, Observable} from 'rxjs';
import { Storage } from "@ionic/storage";
import {catchError, mergeMap} from "rxjs/operators";
import {API_TOKEN} from "../../config";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private storage: Storage) {}

    /**
     * Set an APPCMS-Token on every http request, either from storage if user is logged in or the static api-token if the user is on the frontend booking page
     * @param {HttpRequest<any>} request
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.getTokenFromStorage().pipe(
            mergeMap((token: string) => {
                let newRequest = request.clone({
                    headers : new  HttpHeaders().set("Content-Type", "application/json").set("APPCMS-Token", token)
                });
                return next.handle(newRequest);
            }),
            catchError((err) => {
                let newRequest = request.clone({
                    headers : new  HttpHeaders().set("Content-Type", "application/json").set("APPCMS-Token", API_TOKEN)
                });
                return next.handle(newRequest);
            })
        );
    }

    /**
     * Get the api token from local storage
     * @returns {Observable<string>}
     */
    getTokenFromStorage() : Observable<string> {
        return from(this.storage.get('token'));
    }

}