import { Injectable } from '@angular/core';
import {detailitem} from "./detailitem";
import {ApiService} from "../api/api.service";
import {listitem} from "./listitem";
import {qualificationItem} from "./qualificationItem";
import { USER_GROUP } from 'src/app/config';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

    private employeeObject: detailitem;

    constructor(private api: ApiService) { }

    setEmployeeObject(employee : detailitem) {
        this.employeeObject = employee;
    }

    getEmployeeObject() : Promise<detailitem> {
        return Promise.resolve(this.employeeObject);
    }

    /**
     * Load employee list items
     * @returns {Promise<listitem[]>}
     */
    loadListItems() : Promise<listitem[]> {
        let params = {
            "select": ["id", "prefix", "firstname", "lastname", "isActive", "sorting", "nameInternal", "color"],
            "from": "Employee",
            "where": {"isActive=?": true},
            "orderBy": {"sorting": "ASC"}
        };

        return this.api.post('/api/query', params).then((listitems: listitem[]) => {
            return Promise.resolve(listitems);
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    /**
     * Load employee data
     * @param {string} id
     * @returns {Promise<detailitem>}
     */
    loadEmployeeData(id : string) : Promise<detailitem> {
        let params = {
            "entity": "Employee\\Employee",
            "id": id
        };
        return this.api.post('/api/single', params).then((result: any) => {
            console.log(result);
            this.setEmployeeObject(result['data']);
            return Promise.resolve(result['data']);
        }).catch((error) => {
            return Promise.reject(error);
        })
    }

    /**
     * Load all qualifications for this employee
     * @param {string} employeeId
     * @returns {Promise<qualificationItem[]>}
     */
    loadQualificationsItems(employeeId : string) : Promise<qualificationItem[]> {
        return this.api.get('/api/employee/'+employeeId+'/qualifications').then((qualifications : qualificationItem[]) => {
            return Promise.resolve(qualifications);
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    /**
     * Load all services
     * @returns {Promise<any[]>}
     */
    loadServices(employeeId: string) : Promise<any[]> {
        let params = {
            "employeeId": employeeId
        };

        return this.api.post('/api/employee/services', params).then((services: any[]) => {
            return Promise.resolve(services);
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    /**
     * Edit qualifications for the employee
     * @param {string} employeeId
     * @param {any[]} services
     * @returns {Promise<object>}
     */
    saveQualification(employeeId: string, services: any[]) : Promise<object> {
        let params = {
            "entity": "Employee\\Employee",
            "id": employeeId,
            "data": {
                "qualifications": services
          }
        };
        return this.api.post('/api/update', params).then((response: any) => {
            return Promise.resolve(response);
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    /**
     * Save employee
     * @param {detailitem} employee
     * @param {string} id
     * @returns {Promise<object>}
     */
    saveEmployee(employee : detailitem, id: string = null) : Promise<object> {
        let params = {
            "entity": "Employee\\Employee",
            "data": {
                "prefix": employee.prefix,
                "firstname": employee.firstname,
                "lastname": employee.lastname,
                "nameInternal": employee.nameInternal,
                "phone": employee.phone,
                "email": employee.email,
                "color": employee.color,
                "colorTint": employee.colorTint,
                "colorShade": employee.colorShade
            }
        };

        if(employee.employeeUser !== undefined && employee.employeeUser !== null) {
            params.data['employeeUser'] = {
                "id": (employee.employeeUser.id !== undefined && employee.employeeUser.id !== null) ? employee.employeeUser.id : null,
                "alias": employee.employeeUser.alias,
                "group": {
                    "id": USER_GROUP
                }
            };
            if(employee.employeeUser.pass !== undefined && employee.employeeUser.pass !== null && employee.employeeUser.pass.trim() !== '') {
                params.data['employeeUser']['pass'] = employee.employeeUser.pass;
            }
        }
        
        console.log('##### PARAMS',params);
        if(id) {
            params["id"] = id;
            return this.api.post('/api/update', params).then((response: any) => {
                this.setEmployeeObject(employee);
                return Promise.resolve(response);
            }).catch((error) => {
                return Promise.reject(error);
            })
        } else {
            return this.api.post('/api/insert', params).then((response: any) => {
                this.setEmployeeObject(employee);
                return Promise.resolve(response);
            }).catch((error) => {
                return Promise.reject(error);
            })
        }

    }

}
