import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Storage } from "@ionic/storage";
import {AuthService} from "./auth.service";
import {NavController} from "@ionic/angular";
import {APP_DEBUG} from "../../config";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private auth: AuthService, private navCtrl: NavController, private storage: Storage) {
    }

    /**
     * Check if the http request throws an error and logout the user if he is not authorized
     * @param {HttpRequest<any>} request
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                this.storage.clear().then((response) => {
                    this.navCtrl.navigateRoot('/login');
                }).catch((error) => {
                    if(APP_DEBUG) {
                        console.log(error);
                    }
                });

            }
            return throwError(err);
        }))
    }
}