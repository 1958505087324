import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import {MenuController} from "@ionic/angular";
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class MenuService implements CanActivate {

    constructor(private menu: MenuController, private router: Router, private auth: AuthService) { }

    /**
     * Disable the backend main menu in the fronted if the user is not authenticated
     * @param {ActivatedRouteSnapshot} route
     * @return {Promise<boolean>}
     */
    canActivate(route: ActivatedRouteSnapshot) : Promise<boolean> {
        return this.auth.isAuthenticated().then((isAuth) => {
            if(isAuth) {
                this.menu.enable(true, 'mainmenu');
                return true;
            }
            this.menu.enable(false, 'mainmenu');
            return true;
        }).catch((error) => {
            console.log(error);
            this.menu.enable(false, 'mainmenu');
            return true;
        });
    }
}
