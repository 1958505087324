import { Injectable } from '@angular/core';

import { Resolve } from '@angular/router';
import {ActivatedRouteSnapshot} from "@angular/router";
import {EmployeeService} from "./employee.service";

@Injectable()
export class EmployeeResolver implements Resolve<any> {

    constructor(private employeeService: EmployeeService) {}

    resolve(route: ActivatedRouteSnapshot) {
        return this.employeeService.loadEmployeeData(route.params.id);
    }
}