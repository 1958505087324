import { Component } from '@angular/core';

import {AlertController, MenuController, NavController, Platform} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {AuthService} from "./services/auth/auth.service";
import { Storage } from "@ionic/storage";
import {Router, RouterEvent} from "@angular/router";
import {APP_DEBUG} from "./config";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

    activePath: string = '';

    constructor(
        private alertCtrl: AlertController,
        public auth: AuthService,
        private menuCtrl: MenuController,
        private navCtrl: NavController,
        private platform: Platform,
        private router: Router,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar
    ) {
        this.initializeApp();
        this.router.events.subscribe((event: RouterEvent) => {
            if(event.url !== undefined) {
                this.activePath = event.url;
            }        
        });
    }

    initializeApp() {
        console.log('INITIALIZE');
        this.platform.ready().then(() => {
          this.statusBar.styleDefault();
          this.splashScreen.hide();
        });
    }

    ngOnInit(){
    }

    goToRoot(url) {
        this.activePath = url;
        this.menuCtrl.close();
        this.navCtrl.navigateRoot(url);
    }

    async logout(){
        let message     = 'Wollen Sie sich wirklich abmelden?';
        let dismissText = 'Abbrechen';

        const alert = await this.alertCtrl.create({
            header: 'Logout',
            message: message,
            buttons: [
                {
                    text: dismissText,
                    handler: () => {
                        this.menuCtrl.close();
                    }
                },
                {
                    text: 'Logout',
                    handler: () => {
                        this.auth.logout().then((response) => {
                            this.menuCtrl.close();
                            this.navCtrl.navigateRoot('/login');
                        }).catch((error) => {
                            if(APP_DEBUG) {
                                console.log(error);
                            }
                        });


                    }
                }
            ]
        });

        await alert.present();
    }
}
