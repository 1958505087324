import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CONTENTFLY_URL} from "../../config";
import { Storage } from "@ionic/storage";

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(private http: HttpClient, public storage: Storage) { }

    /**
     * HTTP GET request
     * @param {string} endpoint
     * @return {Promise<object>}
     */
    get(endpoint : string) : Promise<object> {
        let url = CONTENTFLY_URL+endpoint;
        return this.http.get(url).toPromise().then((response) => {
            return Promise.resolve(response);
        }).catch((error) => {
            return Promise.reject(error);
        });
    }

    /**
     * HTTP POST request
     * @param {string} endpoint
     * @param {object} data
     * @return {Promise<object>}
     */
    post(endpoint : string, data : object) : Promise<object> {
        let url = CONTENTFLY_URL+endpoint;
        return this.http.post(url, data).toPromise().then((response) => {
            return Promise.resolve(response);
        }).catch((error) => {
            return Promise.reject(error);
        });
    }
}
