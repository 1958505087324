import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import {MenuController} from "@ionic/angular";
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(private menu: MenuController, private router: Router, private auth: AuthService) { }

    /**
     * Check if the user can access the backend page
     * @param {ActivatedRouteSnapshot} route
     * @return {Promise<boolean>}
     */
    canActivate(route: ActivatedRouteSnapshot) : Promise<boolean> {
        return this.auth.isAuthenticated().then((isAuth) => {
            if(isAuth) {
                this.menu.enable(true, 'mainmenu');
                return true;
            }
            this.menu.enable(false, 'mainmenu');
            this.router.navigate(['/login']);
            return false;
        }).catch((error) => {
            this.menu.enable(false, 'mainmenu');
            this.router.navigate(['/login']);
            return false;
        });
    }
}
