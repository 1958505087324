import { Injectable } from '@angular/core';

import { Resolve } from '@angular/router';
import {BranchService} from "./branch.service";
import {ActivatedRouteSnapshot} from "@angular/router";

@Injectable()
export class BranchResolver implements Resolve<any> {

    constructor(private branchService: BranchService) {}

    resolve(route: ActivatedRouteSnapshot) {
        return this.branchService.loadBranchData(route.params.id);
    }
}